import React from 'react'
import projectsData from './projectsdata'
import ProjectCard from './projectCard'

const Projects = ()=> {

   let myprojects = projectsData()

   const renderHelper = () =>{
      const diplay =  myprojects.map(project=>{
           return(<ProjectCard project={project} /> )
       })
       return diplay
   }
   //className='projects-grid'
    return (
        <div id="myprojects" >
          {renderHelper()}
        </div>
    )
}
export default Projects