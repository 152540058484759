import React from 'react'
import '../assets/styles/teammates.css'
export default function teammates() {

    const recom = [{
        image:"https://i.ibb.co/G2DYp5N/sd.jpg",
        rec:"Rida was my first partner at Microverse, and I really enjoyed his enthusiasm and passion for solving problems. He is always willing to assist whenever called upon. He is a great and fast learner. He displays great professionalism and has good work ethics. If you are looking for a young, talented, and passionate team member, I would strongly recommend Rida to you because he could be the motivation you are looking for in your firm. I strongly recommend Rida without any reservations.Best regards",
    },
    {
        image:"https://i.ibb.co/8jsWKg5/fs.jpg",
        rec:"Rida is an awesome person to work with, he's self-motivated and highly proactive when it comes to helping people and getting things done. I worked on several projects with Rida and It was always a happy hour working with him. I really appreciate his effort while working, anyone will be happy to work alongside him!",
    },
    
]

const displayrec = () =>{
    const rec = recom.map(r=>(
        <div className='recomondation'>
            <img src={ r.image } alt="fsf" />
            <p> {r.rec} </p>
        </div>
    ))
    return rec
}
    return (
        <div>
            <h1 className="team-rec">What my teammates say about me</h1>
        <div className="recom-list">
            {displayrec()}
        </div>
        </div>
    )
}
