import React from 'react'
import Header from './header'
import Projects from './projects'
import Teammates from './teammates'
import '../assets/styles/base.css'
import About from './about'
import Contact from './contact'

export default function App() {
    return (
        <div>
            <Header />
            <Projects />
            <div className='about-othersr'>
            <About />
            <Teammates />
            </div>
            <Contact />
        </div>
    )
}
