import React from 'react'
import '../assets/styles/projectCard.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import {faLink} from '@fortawesome/free-solid-svg-icons'

export default function projectCard({project}) {

    const techused= () =>{
        const techs = project.techs.map(tech=>(
            <li> {tech} </li>
        ))
        return techs
    }
    

 
    return (
        <div className='project-info'>
            <div className="left">
            <img src={project.img} alt='project-img' />
            </div>
            <div id={project.id} className='rigth'>
            <h1>{project.name} </h1>
            <p>{project.description}</p>
            <p className="builtwith">Built with:</p>
            <ul>
                {techused()}
            </ul>
            <div className='pro-links'>
            <a target="_blanck" href={project.repo}><FontAwesomeIcon icon={faGithub} /> Repo</a>
            <a target="_blanck" href={project.live}><FontAwesomeIcon icon={faLink} /> Live</a>
            </div>
            </div>
        </div>
    )
}
