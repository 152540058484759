import React from 'react'
import Nav from './nav'
import terminal from '../assets/images/terminal.svg'
import '../assets/styles/header.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub , faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope }  from '@fortawesome/free-regular-svg-icons'
import Typing from 'react-typing-animation';


const header=()=> {
  
    const AnimatedTypingComponent = () => (
        <Typing speed={10}>
         <p>  <img className="terminal-icon" src={terminal} alt='terminal' /> <Typing.Delay ms={1000} />  <span>Hi, I'm Rida, Nice To Meet You.</span> </p>
         <p>  <img className="terminal-icon" src={terminal} alt='terminal' /> <Typing.Delay ms={1000} />  I'm a Full-Stack web developer </p>
         <p>  <img className="terminal-icon" src={terminal} alt='terminal' /> <Typing.Delay ms={1000} />I am passionate about coding and creating remarkable web apps.</p>
          <p>  <img className="terminal-icon" src={terminal} alt='terminal' /> <Typing.Delay ms={1000} />  Check out some of my work below, and feel free to contact me.</p>
        </Typing>
      );
    
    return (
        <div id="header" className="header">
            <Nav />
            <div className="short-intro">
            <div className='ter-cir'>
                 <div></div>
                <div></div>
                <div></div>
            </div>
            <div className='terminal'>
             {AnimatedTypingComponent()}
            </div>

            <div className="contact-info">
              <h1>LET'S CONNECT</h1>
              <div>
             <a target="_blank" href="https://github.com/rida-elbahtouri" className="icons-con" > <FontAwesomeIcon icon={faGithub} /> </a>
             <a target="_blank" href="mailto: elbahtouri0123@gmail.com" className="icons-con" > <FontAwesomeIcon icon={faEnvelope} /> </a>
             <a target="_blank" href="https://twitter.com/RElbahtouri" className="icons-con" > <FontAwesomeIcon icon={faTwitter} /> </a>
             <a target="_blank" href="https://www.linkedin.com/in/rida-elbahtouri/" className="icons-con" > <FontAwesomeIcon icon={faLinkedin} /> </a>
              </div>
            </div>
            </div>
        </div>
    )
}
export default header