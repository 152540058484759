const projectsData = () =>{

    const projects = [
        {
            id:'1',
            name:'TechStore',
            description:'This is a web application I built where users can explore different products based on category or they can search for a product by name, they can also sign up sign in, buy, rate, and comment on products.',
            techs:['Ruby on Rails','Ruby','JavaScript','Rspec' ,'Capybara','Html5', 'Css3', 'Sass'],
            img:'https://i.ibb.co/rpYYm2T/Tech-Store.png',
            repo:'https://github.com/rida-elbahtouri/Techstore',
            live:'https://ancient-journey-67088.herokuapp.com/'
        },
        {
            id:'2',
            name:'Wedding Venues',
            description:'This project is a Full-stack app that allows users to create an account, search for venues, and create a favorites list.',
            techs:['ReactJs' ,'Redux' ,'Sass','JavaScript','JSX','ESLint','Jest','Axios','Ruby','Ruby On Rails','PoPostgreSQL','Rspec' ],
            img:'https://i.ibb.co/fMhs56P/121212.jpg',
            repo:'https://github.com/rida-elbahtouri/wedddingvenue-frontend',
            live:'https://weddingvenues.herokuapp.com/'
        },
        {
            id:'3',
            name:'Thought Space',
            description:'This is a social media app designed to enable a user to share their thoughts with friends.',
            techs:['Html5', 'Css3', 'Sass', 'Rails' ,'Ruby' ,'Rspec' ,'Capybara' ],
            img:'https://i.ibb.co/6JQ5Z54/fg.png',
            repo:'https://github.com/rida-elbahtouri/CarsDiscussion',
            live:'https://shrouded-plains-90260.herokuapp.com/'
        },
        {
            id:'4',
            name:'News week clone',
            description:'This is a clone of the Newsweek website that I built based on Bootstrap.',
            techs:['Html5' ,'Css3' ,'Bootstrap4' ],
            img:'https://i.ibb.co/KKJkkQj/ne.png',
            repo:'https://github.com/rida-elbahtouri/-Bootstrap-project',
            live:'https://rawcdn.githack.com/rida-elbahtouri/-Bootstrap-project/20ac1b9bdef555549cb8b9bc6241ec80b34befc5/index.html'
        },



    ]
    return projects
}
export default projectsData