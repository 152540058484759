import React from 'react'

import js from '../assets/images/front/js.png'
import css from '../assets/images/front/css.png'
import html from '../assets/images/front/html.png'
import react from '../assets/images/front/react.png'
import redux from '../assets/images/front/redux.png'



import Ruby from '../assets/images/back/ruby.png'
import Rails from '../assets/images/back/rails.png'
import Capybara from '../assets/images/back/capybara.png'
import Rspec from '../assets/images/back/rspec.png'
import Pg from '../assets/images/back/pg.png'
import Se from '../assets/images/back/se.png'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'


export default function skills() {
    const front_skills = [
        {
            name:'JavaScript',
            img:js
        },
        {
            name:'Css3',
            img:css
        }, {
            name:'Html5',
            img: html
        },
         {
            name:'Redux',
            img:redux
        },
        {
            name:'Reactjs',
            img:react
        },
       
    ]

    const backend_skills = [
        {
            name:'Ruby',
            img:Ruby
        },
        {
            name:'Rails',
            img:Rails
        }, {
            name:'PostgreSQL',
            img: Pg
        },
         {
            name:'Rspec',
            img:Rspec
        },
        {
            name:'Capybara',
            img:Capybara
        },
        {
            name:'Selenium',
            img:Se
        },
       
    ]

    const diplayic = (array) => {
        const res = array.map(sk=>(
            <div className='skillbox'>
                <img src={ sk.img } alt={sk.name} />
                <p> {sk.name} </p>
            </div>
        ))
        return res
    }

    const hideshow = (div,arr)=>{
        const det = document.getElementById(div)
        const arrow = document.getElementById(arr)

        if (det.className === 'flexit'){
            det.className= 'none'
            arrow.classList.remove('rotate')
        }else {
            det.className = 'flexit'
            arrow.classList.add('rotate')
        } 
        
        
    }
    return (
        <div className='skills-con'>
            <h1 className="techs-iknow">Technologies</h1>
            
          
            <h1 className='ssfrontend' onClick ={()=>{hideshow('ssfrontend','ar1') }} >Front End <span id="ar1" className='arrow-skills rotate'><FontAwesomeIcon icon={faCaretRight} /></span> </h1>
              <div className="skcont">
        <div id='ssfrontend' className='flexit'>
            { diplayic(front_skills)}
        </div>
        </div>
        
        
      
        <h1 className='ssBackend' onClick ={()=>{hideshow('ssBackend','ar2') }}>Back End <span id="ar2" className='arrow-skills'><FontAwesomeIcon icon={faCaretRight} /></span> </h1>
          <div className="skcont">
        <div id='ssBackend' className='none'>
            { diplayic(backend_skills)}
        </div>
        </div>
        
        
      
        <h1 className='ssskills' onClick ={()=>{hideshow('ssskills','ar3') }} >Skills <span id="ar3" className='arrow-skills'><FontAwesomeIcon icon={faCaretRight} /></span> </h1>
          <div className="skcont">
        <div id='ssskills' className='none'>
           <ul className="extra-skills">
              <li> Git </li>
               <li> GitHub </li>
               <li>  Heroku </li>
               <li>   Netlify </li>
               <li>    Mobile/Responsive Development </li>
               <li>  TDD  </li>
               <li>  Chrome Dev Tools </li>
                 </ul>
        </div>
        </div>
        </div>
    )
}
