import React from 'react'
import "../assets/styles/contact.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub , faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope }  from '@fortawesome/free-regular-svg-icons'
export default function contact() {
    return (
        <div id="contactme" className="contact-me">
            <h1>Contact me</h1>

            <div className="contact-list">
            <a target="_blank" href="https://github.com/rida-elbahtouri" className="icons-contact-list" > <FontAwesomeIcon icon={faGithub} /> </a>
             <a target="_blank" href="mailto: elbahtouri0123@gmail.com" className="icons-contact-list" > <FontAwesomeIcon icon={faEnvelope} /> </a>
             <a target="_blank" href="https://twitter.com/RElbahtouri" className="icons-contact-list" > <FontAwesomeIcon icon={faTwitter} /> </a>
             <a target="_blank" href="https://www.linkedin.com/in/rida-elbahtouri/" className="icons-contact-list" > <FontAwesomeIcon icon={faLinkedin} /> </a>
            
   
            </div>


            <div className="footer">
                <h3>Made By Rida Elbahtouri   2020</h3>
                <h3>Designed By Rida Elbahtouri using AdobeXD</h3>
            </div>
        </div>
    )
}
