import React from 'react'
import '../assets/styles/nav.css'
class nav extends React.Component {

    componentDidMount(){
        window.addEventListener('scroll', this. onscroll);

    }
   
    onscroll=()=>{
       
       const humb = document.getElementById('huberger')
        const nav = document.getElementById('nav')
        const ext = document.getElementById('extraspan')
        const clop = document.getElementById('closebtn')
        if (nav){
           const sticky = nav.offsetTop;
        if (window.pageYOffset > sticky) {
            humb.className="huberger";
            nav.className="none"
            clop.classList.remove('close')
            ext.className = 'extraspan'

        } else {
            humb.className="none";
            nav.className='nav'
        }
        }
   }

   showNav=()=>{
    const nav = document.getElementById('nav')
    const ext = document.getElementById('extraspan')
    const clop = document.getElementById('closebtn')
    

    if(nav.className === 'none'){
    nav.className = "fixed-nav"
    nav.classList.add('nav')
    clop.classList.add('close')
    ext.className = 'none'
    }else {
        nav.className='none'
        clop.classList.remove('close')
        ext.className = 'extraspan'
    }
    

   }

    render(){
           return (
        <div onScroll={this.onscroll} id="navbar" className="navbar">
            <div onClick={this.showNav} id="huberger" className="none">
                <div id="closebtn" className="dashs">
                 <span id="extraspan" className="extraspan"></span>
                <span></span>
                <span className="last-item"></span>
                </div>
            </div>
            <div  id="nav" className="nav">
            <h1><a href="#header">RD</a></h1>

            <ul>
                <li> <a href="#aboutme"> About Me</a>   </li>
                <li> <a href="#myprojects"> My Projects </a> </li>
                <li className="navelemn"> <a href="#contactme"> Contact Me </a> </li>
            </ul>
            </div>
        </div>
    )
    }
 
}
export default nav