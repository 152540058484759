import React from 'react'
import '../assets/styles/about.css'
import profile from '../assets/images/40546351.jpg'
import Skills from './skills'
export default function About() {

    

  


    return (
        <div id="aboutme" className='aboutme'>
            <div className='information'>
            <h1>About me</h1>
            <img className='myphoto' src={profile} alt="myphoto" />
            <h3>Hi, I’m Rida Elbahtouri.</h3>
            <p className='mybio'>I'm a full-stack developer, passionate about creating and developing new web apps.<br/>

            I love programming and solving tricky problems.<br/>

            As a full-stack web developer, I continue to learn new programming languages, frameworks and explore new fields like game development and machine learning.</p>
            
            <a target="_blank" className='resume-link' href='https://docs.google.com/document/d/1ZpoAM-Dar5tXn1CPLdRM5W2h9LodazDp_dIoWxUk444/edit?usp=sharing'>See My Resume</a>
            </div>
            <div className='skills'>
        
            <Skills />
        
            </div>
        </div>
    )
}
